import { Block, Divider, Flexbox } from '@stage-ui/core'
import FlexboxTypes from '@stage-ui/core/layout/Flexbox/types'

type PageCircleLinkProps = FlexboxTypes.Props

const PageCircleLink = (props: PageCircleLinkProps) => {
  const { ...restProps } = props
  return (
    <Flexbox {...restProps} alignItems="center" flex={1}>
      <Block
        flexShrink={0}
        borderRadius="100%"
        w="0.75rem"
        h="0.75rem"
        backgroundColor="gray300"
      />
      <Divider color="gray300" />
      <Block
        flexShrink={0}
        borderRadius="100%"
        w="1.875rem"
        h="1.875rem"
        backgroundColor="gray300"
      />
    </Flexbox>
  )
}

export default PageCircleLink
