import { Block, Divider, Flexbox, Paragraph } from '@stage-ui/core'
import FlexboxTypes from '@stage-ui/core/layout/Flexbox/types'

type PageRhombusLineLineProps = {
  title: string
} & FlexboxTypes.Props

const PageRhombusLineLine = (props: PageRhombusLineLineProps) => {
  const { title, onClick, ...restProps } = props
  return (
    <Flexbox mx={['1.25rem', '0.5rem 0']} {...restProps}>
      <Flexbox column alignItems="center">
        <Block backgroundColor="black" w="0.375rem" h="0.375rem" borderRadius="100%" />
        <Divider flex={1} color="black" vertical />
        <Block
          backgroundColor="black"
          w={['1.875rem', '1.5rem']}
          h={['1.875rem', '1.5rem']}
          style={{
            transform: 'rotate(45deg)',
          }}
        />
      </Flexbox>
      <Paragraph ml={['1.25rem', '0']} size={['4rem', '1.5rem']} weight={800}>
        {title}
      </Paragraph>
    </Flexbox>
  )
}

export default PageRhombusLineLine
