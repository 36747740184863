import { Flexbox, Header, Paragraph } from '@stage-ui/core'
import FlexboxTypes from '@stage-ui/core/layout/Flexbox/types'
import ParagraphTypes from '@stage-ui/core/content/Paragraph/types'
import HeaderTypes from '@stage-ui/core/content/Header/types'

import IconGlass, { glassIcons } from './IconGlass'

type PageIconTextProps = {
  title: React.ReactNode
  label: React.ReactNode
  icon: keyof typeof glassIcons
  titleProps?: HeaderTypes.Props
  labelProps?: ParagraphTypes.Props
  textProps?: ParagraphTypes.Props
} & FlexboxTypes.Props

const PageIconText = (props: PageIconTextProps) => {
  const {
    title,
    label,
    icon,
    labelProps,
    titleProps,
    textProps,
    onClick,
    children,
    ...restProps
  } = props
  return (
    <Flexbox column {...restProps}>
      <IconGlass type={icon} size="5rem" mb="1.25rem" />
      {!!label && (
        <Paragraph
          size="0.825rem"
          color="gray600"
          // @ts-ignore
          style={{
            textTransform: 'uppercase',
            ...labelProps,
          }}
        >
          {label}
        </Paragraph>
      )}
      <Header
        as="h4"
        size={['1.5rem', '1.25rem']}
        color="black"
        // @ts-ignore
        style={{
          ...titleProps,
        }}
      >
        {title}
      </Header>
      <Paragraph
        mt="1.25rem"
        size={['1.125rem', '0.875rem']}
        color="black"
        weight={300}
        lineHeight="150%"
        {...textProps}
      >
        {children}
      </Paragraph>
    </Flexbox>
  )
}

export default PageIconText
